import axios from '@/plugins/axios'

/**
 * Activate dashboard
 */
const activateDashboard = async id => {
  try {
    const response = await axios.post(`admin/dashboards/${id}/activate`, {
      _method: 'PUT'
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Create coupon
 */
const createDashboard = async ({
  // eslint-disable-next-line camelcase
  company_id,
  name,
  // eslint-disable-next-line camelcase
  embed_url
}) => {
  try {
    const form = {
      company_id,
      name,
      embed_url
    }
    const response = await axios.post('admin/dashboards', form)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Destroy dashboard
 */
const destroyDashboard = async id => {
  try {
    const response = await axios.post(`admin/dashboards/${id}`, {
      _method: 'DELETE'
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get single dashboard
 */
const getDashboard = async id => {
  try {
    const response = await axios.get(`admin/dashboards/${id}`)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get dashboards
 */
const getDashboards = async ({
  companyId,
  page = 1,
  itemsPerPage = 9
}) => {
  try {
    const params = {
      company_id: companyId
    }
    params.page = page
    params.items_per_page = itemsPerPage
    const response = await axios.get('admin/dashboards', {
      params: params
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Update dashboard
 */
const updateDashboard = async (id, {
  // eslint-disable-next-line camelcase
  company_id,
  name,
  // eslint-disable-next-line camelcase
  embed_url
}) => {
  try {
    const form = {
      company_id,
      name,
      embed_url
    }
    form._method = 'PUT'
    const response = await axios.post(`admin/dashboards/${id}`, form)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  activateDashboard,
  createDashboard,
  destroyDashboard,
  getDashboard,
  getDashboards,
  updateDashboard
}
