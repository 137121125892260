<template>
  <div class="dashboard-list-table">
    <div v-if="loader && localDashboards.length === 0" class="text-center">
      <Loader
        :size="'big'"
      />
    </div>
    <template v-else>
      <LoaderOverlay
        size="big"
        color="color2"
        v-if="overlayLoader"
      />
      <div class="d-flex justify-content-end">
        <button
          class="btn btn-primary"
          @click="openNewDashboardModal"
        >
          <i class="fa-solid fa-plus"></i> Criar dashboard
        </button>
      </div>
      <div class="table-responsive">
        <!--begin::Table-->
        <table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_ecommerce_products_table">
          <!--begin::Table head-->
          <thead>
            <!--begin::Table row-->
            <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
              <th class="min-w-70px">Nome</th>
              <th class="min-w-100px">Tipo</th>
              <th class="min-w-100px">Plataforma</th>
              <th class="min-w-100px">Embed URL</th>
              <th class="min-w-70px">Criado em</th>
              <th class="min-w-70px">Ativo</th>
              <th class="min-w-70px">Ações</th>
            </tr>
            <!--end::Table row-->
          </thead>
          <!--end::Table head-->
          <!--begin::Table body-->
          <tbody class="fw-semibold text-gray-600">
            <!--begin::Table row-->
            <tr v-for="(dashboard, index) in localDashboards" :key="index">
              <td>
                <span>{{ dashboard.name }}</span>
              </td>
              <td>
                <span>{{ dashboard.type }}</span>
              </td>
              <td>
                <span>{{ dashboard.platform }}</span>
              </td>
              <td>
                <span>{{ dashboard.embed_url }}</span>
              </td>
              <td>
                <span>{{ dashboard.created_at | formatDate }}</span>
              </td>
              <td>
                <span>{{ dashboard.is_active ? 'Sim' : 'Não' }}</span>
              </td>
              <td class="d-flex gap-2">
                <a class="btn btn-primary mt-4"
                  @click.prevent="openEditDashboardModal(dashboard)"
                  v-b-tooltip.hover title="Editar"
                >
                  <i class="fa-solid fa-pen-to-square p-0"></i>
                </a>
                <Button
                  type="button"
                  class="btn btn-danger mt-4"
                  @click="destroyDashboard(dashboard.id)"
                  v-if="dashboard.is_active"
                  v-b-tooltip.hover title="Desativar"
                >
                  <i class="fa-solid fa-xmark p-0"></i>
                </Button>
                <Button
                  type="button"
                  class="btn btn-success mt-4"
                  @click="activateDashboard(dashboard.id)"
                  v-if="!dashboard.is_active"
                  v-b-tooltip.hover title="Ativar"
                >
                  <i class="fa-solid fa-check p-0"></i>
                </Button>
              </td>
            </tr>
            <!--end::Table row-->
          </tbody>
          <!--end::Table body-->
        </table>
      </div>
      <DashboardEdit
        :dashboard="selectedDashboard"
        :companyId="companyId"
        @dashboard-created="getDashboards"
        @dashboard-updated="getDashboards"
      />
      <!--end::Table-->
      <div class="text-center mt-4">
        <Button
          class="btn-primary"
          :loading="loader"
          @click="loadMore"
          v-if="isLoadMoreVisible"
        >
          Carregar mais
        </Button>
      </div>
    </template>
  </div>
</template>

<script>

import DashboardsService from '@/modules/dashboards/services/dashboards-service'
import NotificationService from '@/modules/notifications/services/notifications-service'
import { parseErrorResponseToArray } from '@/utils'

import Button from '@/components/common/Button/Button'
import DashboardEdit from '@/components/dashboards/DashboardEdit.vue'
import Loader from '@/components/common/Loader/Loader'
import LoaderOverlay from '@/components/common/Loader/LoaderOverlay'

export default {
  name: 'DashboardListTable',
  props: ['companyId', 'dashboards'],
  components: {
    Button,
    DashboardEdit,
    Loader,
    LoaderOverlay
  },
  data () {
    return {
      localDashboards: [],
      loader: false,
      overlayLoader: false,
      page: 1,
      isLoadMoreVisible: true,
      selectedDashboard: null
    }
  },
  created () {
    if (this.dashboards) {
      this.localDashboards = this.dashboards
      this.isLoadMoreVisible = false
    } else {
      this.getDashboards()
    }
  },
  watch: {
    reload (newValue) {
      if (newValue) this.reset()
    }
  },
  methods: {
    /**
     * Submit
     */
    async activateDashboard (id) {
      this.overlayLoader = true
      try {
        await DashboardsService.activateDashboard(id)
        const notification = {
          notification: {
            type: 'success',
            content: 'Dashboard ativado com sucesso'
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
        this.getDashboards()
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.overlayLoader = false
    },
    /**
     * Submit
     */
    async destroyDashboard (id) {
      this.overlayLoader = true
      try {
        await DashboardsService.destroyDashboard(id)
        const notification = {
          notification: {
            type: 'success',
            content: 'Dashboard desativado com sucesso'
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
        this.getDashboards()
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.overlayLoader = false
    },
    /**
     * Get dashboards
     */
    async getDashboards () {
      if (!this.companyId) return false

      this.loader = true
      this.localDashboards = []
      try {
        const dashboards = await DashboardsService.getDashboards({
          companyId: this.companyId,
          page: this.page
        })
        this.localDashboards = [...this.localDashboards, ...dashboards.data]
        this.isLoadMoreVisible = dashboards.meta.current_page !== dashboards.meta.last_page
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    /**
     * Load more
     */
    loadMore () {
      this.page++
      this.getDashboards()
    },
    /**
     * Open modal to edit dashboard
     */
    openEditDashboardModal (dashboard) {
      this.selectedDashboard = dashboard
      this.$bvModal.show('dashboard-edit')
    },
    /**
     * Open modal to create dashboard
     */
    openNewDashboardModal () {
      this.selectedDashboard = null
      this.$bvModal.show('dashboard-edit')
    },
    /**
     * Reset and reload
     */
    reset () {
      this.page = 1
      this.localDashboards = []
      this.getDashboards()
    }
  }
}
</script>
