<template>
  <b-modal
    id="dashboard-edit"
    :modal-class="'default-modal'"
    header-class="justify-content-between"
    :hide-footer="true"
    @hidden="hidden"
    @shown="shown"
  >

    <template #modal-header="{ close }">
      <div class="text-center">
        <h2 class="highlighted-color1">
          <span>{{modalTitle}} dashboard</span>
        </h2>
      </div>
      <!--begin::Close-->
      <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close" @click="close()">
          <span class="svg-icon svg-icon-1">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
            </svg>
          </span>
      </div>
      <!--end::Close-->
    </template>
    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <div class="mt-4">
        <form class="default-form" @submit.prevent="">
          <div class="mb-5">
            <label class="form-label fw-semibold required">Nome</label>
            <input type="text" class="form-control" v-model="form.name">
          </div>
          <div class="mb-5">
            <label class="form-label fw-semibold required">Embed URL</label>
            <input type="url" class="form-control" v-model="form.embed_url">
          </div>
          <div class="mt-3 text-center">
            <Button
              class="btn btn-primary"
              @click="submit"
              :loading="loader"
            >
              {{ buttonText }}
            </Button>
          </div>
        </form>
    </div>
  </b-modal>
</template>

<script>

import DashboardsService from '@/modules/dashboards/services/dashboards-service'
import NotificationService from '@/modules/notifications/services/notifications-service'
import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'

export default {
  name: 'DashboardEdit',
  props: ['dashboard', 'companyId'],
  components: {
    AlertWraper,
    Button
  },
  data () {
    return {
      content: null,
      content_type: 'error',
      loader: false,
      form: {
        name: '',
        embed_url: '',
        company_id: ''
      }
    }
  },
  computed: {
    buttonText () {
      return this.dashboard ? 'Salvar' : 'Criar'
    },
    modalTitle () {
      if (this.dashboard) return 'Editar'
      else return 'Criar'
    }
  },
  methods: {
    /**
     * On modal hidden
     */
    hidden () {
      this.content = null
      this.form.name = ''
      this.form.embed_url = ''
    },
    /**
     * Submit form
     */
    async submit () {
      this.loader = true

      try {
        this.form.company_id = this.companyId
        const form = JSON.parse(JSON.stringify(this.form))

        if (!this.dashboard) {
          await DashboardsService.createDashboard(form)
        } else {
          await DashboardsService.updateDashboard(this.dashboard.id, form)
        }

        this.$bvModal.hide('dashboard-edit')
        const notification = {
          notification: {
            type: 'success',
            content: 'Dashboard salvo com sucesso'
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
        if (this.dashboard) this.$emit('dashboard-updated', this.dashboard)
        else this.$emit('dashboard-created', this.dashboard)
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.loader = false
    },
    /**
     * Set insertion order
     */
    setDashboard () {
      if (this.dashboard) {
        this.form.name = this.dashboard.name
        this.form.embed_url = this.dashboard.embed_url
      } else {
        this.form.name = ''
        this.form.embed_url = ''
      }
    },
    /**
     * On modal shown
     */
    shown () {
      this.setDashboard()
    }
  }
}
</script>
