<template>
  <div class="advertiser-list-table">
    <div v-if="loader && localAdvertisers.length === 0" class="text-center">
      <Loader
        :size="'big'"
      />
    </div>
    <template v-else>
      <div class="table-responsive">
        <!--begin::Table-->
        <table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_ecommerce_products_table">
          <!--begin::Table head-->
          <thead>
            <!--begin::Table row-->
            <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
              <th class="min-w-100px">Anunciante</th>
              <th class="min-w-100px">Website</th>
              <th class="min-w-70px">Saldo disponível</th>
              <th class="min-w-70px">Saldo alocado</th>
              <th class="min-w-70px">Saldo pendente</th>
              <th class="min-w-70px">Status</th>
              <th class="min-w-70px">Ações</th>
            </tr>
            <!--end::Table row-->
          </thead>
          <!--end::Table head-->
          <!--begin::Table body-->
          <tbody class="fw-semibold text-gray-600">
            <!--begin::Table row-->
            <tr v-for="(advertiser, index) in localAdvertisers" :key="index">
              <td>
                <span>{{ advertiser.name }}</span>
              </td>
              <td>
                <span>{{ advertiser.website }}</span>
              </td>
              <td>
                <span>{{ advertiser.available_amount/100 | formatMoney }}</span>
              </td>
              <td>
                <span>{{ advertiser.allocated_amount/100 | formatMoney }}</span>
              </td>
              <td>
                <span>{{ advertiser.pending_amount/100 | formatMoney }}</span>
              </td>
              <td>
                <span>{{ advertiser.status | formatStatus }}</span>
              </td>
              <td>
                <span>
                  <router-link
                    :to="{ name: 'advertisers.show', params: { id: advertiser.id } }"
                    class="btn btn-sm btn-primary"
                  >
                    <i class="fas fa-eye px-0"></i>
                  </router-link>
                </span>
              </td>
            </tr>
            <!--end::Table row-->
          </tbody>
          <!--end::Table body-->
        </table>
      </div>
      <!--end::Table-->
      <div class="text-center mt-4">
        <Button
          class="btn-primary"
          :loading="loader"
          @click="loadMore"
          v-if="isLoadMoreVisible"
        >
          Carregar mais
        </Button>
      </div>
    </template>
  </div>
</template>

<script>

import AdvertisersService from '@/modules/advertisers/services/advertisers-service'

import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'

export default {
  name: 'AdvertiserListTable',
  props: ['companyId', 'advertisers'],
  components: {
    Button,
    Loader
  },
  data () {
    return {
      localAdvertisers: [],
      loader: false,
      page: 1,
      isLoadMoreVisible: true
    }
  },
  created () {
    if (this.advertisers) {
      this.localAdvertisers = this.advertisers
      this.isLoadMoreVisible = false
    } else {
      this.getAdvertisers()
    }
  },
  watch: {
    reload (newValue) {
      if (newValue) this.reset()
    }
  },
  methods: {
    /**
     * Get advertisers
     */
    async getAdvertisers () {
      if (!this.companyId) return false

      this.loader = true
      try {
        const advertisers = await AdvertisersService.getAdvertisers({
          company_id: this.companyId,
          page: this.page
        })
        this.localAdvertisers = [...this.localAdvertisers, ...advertisers.data]
        this.isLoadMoreVisible = advertisers.meta.current_page !== advertisers.meta.last_page
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    /**
     * Load more
     */
    loadMore () {
      this.page++
      this.getAdvertisers()
    },
    /**
     * Reset and reload
     */
    reset () {
      this.page = 1
      this.localAdvertisers = []
      this.getAdvertisers()
    }
  }
}
</script>
